import { IOrgWithRoles } from "../models/org.types";

type StringMap = {
  [key: string]: string;
};
class ResourcesStore {
  private resourceMap: StringMap = {
    SelfAdministration: "ROLE_ORGANIZATION_ADMIN",
    SharedContactList: "SharedProfile:ContactList:",
    ApplicationAccess: "ROLE_USER",
    "Hygiaso SuperUser": "ROLE_ADMIN",
  };

  async getDetailedRolesForResource(
    resource: string,
    _token: string,
  ): Promise<string[]> {
    // TODO: make something more elaborate and create configuration for
    //  different resource types.
    if (resource.endsWith("ContactList:")) {
      return ["READ", "WRITE"];
    }
    return [];
  }

  async getResourceForRole(role: string, _token: string): Promise<string> {
    for (const key in this.resourceMap) {
      const resourceRole = this.resourceMap[key];
      if (resourceRole.endsWith(":")) {
        if (role.startsWith(resourceRole)) return key;
      } else {
        if (role === resourceRole) return key;
      }
    }

    return role;
  }

  async getAvailableResourceNames(): Promise<string[]> {
    const rv: string[] = [];
    for (const key in this.resourceMap) {
      rv.push(key);
    }
    return rv;
  }

  async resourceForName(name: string): Promise<string> {
    return this.resourceMap[name];
  }

  async resourcesForRoles(roles: string[], token: string): Promise<string[]> {
    const rv = new Set<string>();
    for (const role of roles) {
      const resource = await this.getResourceForRole(role, token);
      rv.add(resource);
    }
    return [...rv];
  }

  async expandRolesForResources(
    orgData: IOrgWithRoles,
    token: string,
  ): Promise<IOrgWithRoles> {
    let assignableRoles = orgData?.assignableRoles ?? [];
    const addableResources = assignableRoles.filter((role) =>
      role.endsWith(":"),
    );

    assignableRoles = assignableRoles.filter((role) => !role.endsWith(":"));

    for (const resource of addableResources) {
      const roles = (
        await this.getDetailedRolesForResource(resource, token)
      ).map((role) => resource + role);
      assignableRoles.push(...roles);
    }

    return {
      ...orgData,
      assignableRoles,
    };
  }
}

export default new ResourcesStore();
